<template>
  <div class="page row">
    <div class="col-12 grid-margin">
      <div class="card">
        <div class="card-body">
          <div class="d-flex mb-3">
            <b-form-group
              class="select col-3 align-center mb-0 pl-0"
              label-for="input11"
              label="Search Username"
            >
              <b-form-input size="sm" v-model="username" @input="loadItems" />
            </b-form-group>
            <b-form-group
              label="Start date"
              class="date-call-log select col-3 align-center"
            >
              <datetime
                v-model="date.start"
                type="datetime"
                :auto="true"
                v-if="date"
                @input="loadItems"
              ></datetime>
            </b-form-group>
            <b-form-group
              label="End date"
              class="date-call-log select col-3 align-center"
            >
              <datetime
                v-model="date.end"
                type="datetime"
                :auto="true"
                v-if="date"
                @input="validEndDate(), loadItems()"
              ></datetime>
            </b-form-group>
          </div>
          <div class="table-responsive">
            <table class="table center-aligned-table">
              <thead>
                <tr>
                  <th>User</th>
                  <th>Issuer</th>
                  <th colspan="2" class="text-center">Balance</th>
                  <th>Timestamp</th>
                  <th>Amount</th>
                  <!-- <th>Groups</th> -->
                  <!-- <th>Status</th> -->
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <th class="text-center">Start</th>
                  <th class="text-center">End</th>
                </tr>
              </thead>
              <tbody v-if="items">
                <tr v-for="item in items" :key="item.contact_id">
                  <td>{{ item.owner }}</td>
                  <td>{{ item.issuer }}</td>
                  <td class="text-center">{{ item.balance_start }}</td>
                  <td class="text-center">{{ item.balance_end }}</td>
                  <td>{{ getDate(item.timestamp) }}</td>
                  <td>{{ item.amount }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Datetime } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";
export default {
  data() {
    return {
      date: {
        start: null,
        end: null,
      },
      username: null,
      items: null,
      load: false,
    };
  },
  components: {
    Datetime,
  },
  created() {
    this.initDate();
  },
  async mounted() {
    this.load = true;
    this.loadItems();
  },
  methods: {
    initDate() {
      let today = new Date();
      const first_date = today.setHours(0, 0, 0, 0);
      const last_date = today.setHours(23, 59, 0, 0);
      this.date.start = this.$moment(first_date).toISOString();
      this.date.end = this.$moment(last_date).toISOString();
    },
    toUnix(date) {
      return this.$moment(date).unix();
    },
    loadItems() {
      if (this.load) {
        let params = {};
        if (!this.date.start || !this.date.end) {
          this.initDate();
        }
        if (this.username) {
          params["issuer"] = this.username;
        }
        this.$store.commit("updateLoading", true);

        params["period"] = [
          this.toUnix(this.date.start),
          this.toUnix(this.date.end),
        ];
        this.$axios
          .post(`${this.$store.state.admin}/transactions/query`, params)
          .then((res) => {
            this.items = res.data.transactions;
            this.$store.commit("updateLoading", false);
          })
          .catch((err) => {
            this.$store.commit("updateLoading", false);
          });
      }
    },
    validEndDate() {
      if (this.date.end < this.date.start) {
        this.date.end = this.date.start;
      }
    },
    getDate(date) {
      return this.$moment.unix(date).format("MMMM Do YYYY, hh:mm");
    },
  },
};
</script>
<style scoped>
.custom-select {
  font-size: 13px;
}
.form-control-sm {
  height: 2rem;
}
.custom-select {
  max-height: 32px;
}
</style>
<style lang="scss">
.form-group.date-call-log {
  margin-bottom: 0;
  padding-left: 0;
  .col-form-label {
    padding-bottom: 0.5rem;
    line-height: normal;
    vertical-align: middle;
    font-size: 0.813rem;
    line-height: 1;
    vertical-align: top;
  }
  input {
    border: 1px solid #f6f2f2;
    font-family: "Poppins", sans-serif;
    font-size: 0.875rem;
    padding: 0.25rem 0.75rem;
    line-height: 14px;
    font-weight: 300;
    height: 2rem;
    width: 100%;
  }
}
</style>